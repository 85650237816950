import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MarkdownLayout.jsx";
import { Helmet } from "react-helmet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet title="Pleasant Design" titleTemplate="%s" mdxType="Helmet" />
    <h1>{`Pleasant Design`}</h1>
    <p>{`With `}<abbr title="minimum viable products">{`MVPs`}</abbr>{` becoming the standard for
software deliverables, many organizations discount the importance of
building software that is `}<strong parentName="p">{`pleasant`}</strong>{` to use.`}</p>
    <p>{`This collection of resources aims to provide clear guidelines and examples for
building software users will love.`}</p>
    <h2>{`Principles`}</h2>
    <h3>{`1. Familiarity`}</h3>
    <p>{`Providing familiar controls and patterns of interaction is integral to a
smooth software experience. While novel elements and patterns may give
the impression of distinctive software, these conflicting experiences
unnecessarily add to users' cognitive load.`}</p>
    <p>{`Using unique but `}<strong parentName="p">{`nonintrusive`}</strong>{` animations, colors, and typography are
great ways to give software a unique look, but it's a common mistake to
"reinvent the wheel" with inputs and controls.`}</p>
    <h3>{`2. Clarification`}</h3>
    <p>{`Some options and interactions are complex and may not be entirely
intuitive to all users.`}</p>
    <p>{`Clarifying elements such as tooltips and links to further documentation
should give users a better understanding of these less intuitive
functions. User demographics and any existing usage data should drive the
inclusion and content of clarifying elements.`}</p>
    <h3>{`3. Feedback`}</h3>
    <p>{`Software controls should provide clear feedback upon any interaction. No
user action should result in uncertainty.`}</p>
    <p>{`Feedback includes states for interactions such as focusing, blurring, or
hovering. For more complex events like form submissions, users should be
notified of each event's progress and results.`}</p>
    <h3>{`4. Efficiency`}</h3>
    <p>{`Minimizing the amount of interaction required to perform a task is
critical to a pleasant experience, but providing efficient paths to many
functions without cluttering interfaces can be challenging.`}</p>
    <p>{`The most common and valuable tasks should be simple to accomplish, with
reasonably few discrete interactions.`}</p>
    <h3>{`5. Attractiveness`}</h3>
    <p>{`While software may be highly functional and intuitive, many users expect
fresh, modern appearances. Attractiveness is highly subjective, but an
appealing and cohesive experience helps keep users engaged.`}</p>
    <p>{`Attractiveness includes consistent styles, polished typography, and a
look likely to be appreciated by target users.`}</p>
    <h3>{`6. Other Considerations`}</h3>
    <h4>{`Accessibility`}</h4>
    <p>{`Many of the above principles apply to developing accessible software to some
degree. Pleasant experiences should not be limited to those interacting via
ideal circumstances.`}</p>
    <p>{`Implementation details are beyond the scope of these principles, but
`}<strong parentName="p">{`best practices`}</strong>{` should be followed to provide accessible controls and
content for interactions involving screen readers, keyboard navigation,
reduced vision, and reduced hearing.`}</p>
    <h4>{`Localization`}</h4>
    <p>{`Whenever possible, software should adapt to the context of its use and provide
users with the expected experience for their preferred language, data formats,
symbols, and legal requirements.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      